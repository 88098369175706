import React from "react";

interface ContentProps {
    children?: React.ReactNode;
}

export const Content: React.FC<ContentProps> = props => (
    <ContentContainer>
        <ContentFull>{props.children}</ContentFull>
    </ContentContainer>
);

interface ContentContainerProps {
    className?: string;
    children?: React.ReactNode;
}

export const ContentContainer: React.FC<ContentContainerProps> = props => (
    <div
        className={`mx-0 gap-1 md:mx-2 md:gap-2 lg:mx-4 lg:gap-4 ${
            props.className ?? ""
        }`}>
        {props.children}
    </div>
);

interface ContentFullProps {
    children?: React.ReactNode;
}

export const ContentFull: React.FC<ContentFullProps> = props => (
    <div className="bg-white p-2 lg:p-4 lg:rounded lg:shadow">{props.children}</div>
);

interface TilesProps {
    children?: React.ReactNode;
}

export const Tiles: React.FC<TilesProps> = props => (
    <div className="grid grid-cols-1 w-full gap-0 md:gap-2 md:grid-cols-2 lg:grid-cols-3 lg:gap-4">
        {props.children}
    </div>
);

interface TileProps {
    children?: React.ReactNode;
}

export const Tile: React.FC<TileProps> = props => (
    <div className="bg-white p-2 md:rounded-sm lg:p-4 lg:rounded lg:shadow">
        {props.children}
    </div>
);

interface StageProps {
    children?: React.ReactNode;
}

export const Stage: React.FC<StageProps> = props => (
    <div className="bg-gray-100 min-h-screen">{props.children}</div>
);

interface SectionHeaderProps {
    title: string;
    children?: React.ReactNode;
}

export const SectionHeader: React.FC<SectionHeaderProps> = props => (
    <div className="py-1 lg:py-2 mx-1 lg:mx-4 lg:flex items-end justify-between">
        <h2 className="text-xl twoverride">{props.title}</h2>
        <div>{props.children}</div>
    </div>
);

interface ContentHeaderProps {
    children?: React.ReactNode;
}

export const ContentHeader: React.FC<ContentHeaderProps> = props => (
    <h2 className="text-xl">{props.children}</h2>
);

interface ContentSubheaderProps {
    children?: React.ReactNode;
}

export const ContentSubheader: React.FC<ContentSubheaderProps> = props => (
    <h6 className="text-gray-500 text-sm -mt-2 mb-4">{props.children}</h6>
);

interface ContentSection2UpProps {
    children?: React.ReactNode;
    className?: string;
}

export const ContentSection2Up: React.FC<ContentSection2UpProps> = props => (
    <div
        className={`mx-0 gap-1 md:flex md:mx-2 md:pb-2 md:gap-4 ${
            props.className ?? ""
        }`}>
        {props.children}
    </div>
);

export const ContentSectionColumn: React.FC<
    React.HTMLAttributes<HTMLDivElement>
> = props => {
    const { className, ...rest } = props;
    const classNameActual = `${className ?? ""} flex-1`;
    return (
        <div {...rest} className={classNameActual}>
            {props.children}
        </div>
    );
};

interface EntityPageHeaderProps {
    children?: React.ReactNode;
}

export const EntityPageHeader: React.FC<EntityPageHeaderProps> = props => (
    <div className="flex bg-gray-100 px-4 py-2 items-center border border-y-gray-300 flex-wrap justify-between w-screen">
        {props.children}
    </div>
);
